// src/App.js
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { sha256 } from 'js-sha256';
import './App.css';

function App() {
  const [fullText, setFullText] = useState('');
  const [startString, setStartString] = useState('');
  const [endString, setEndString] = useState('');
  const [startStatus, setStartStatus] = useState('');
  const [endStatus, setEndStatus] = useState('');
  const [passage, setPassage] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [parseButtonDisabled, setParseButtonDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [words, setWords] = useState([]);
  const [timestamp, setTimestamp] = useState('');
  const [inputMode, setInputMode] = useState('ulysses'); // New state for input mode
  const [freeText, setFreeText] = useState(''); // New state for free-text input

  // Load full text of Ulysses on component mount
  useEffect(() => {
    fetch('/full_ulysses.txt')
      .then(response => response.text())
      .then(text => setFullText(text))
      .catch(error => {
        console.error('Error loading full text:', error);
        alert('Failed to load the full text of Ulysses.');
      });
  }, []);

  // Modify the useEffect hook to include freeText in the dependency array
  useEffect(() => {
    validatePassage();
  }, [startString, endString, fullText, freeText, inputMode]);

  function validatePassage() {
    if (inputMode === 'freeText') {
      const words = freeText.split(/\s+/).filter(word => word.length > 0);
      setWordCount(words.length);
      setParseButtonDisabled(words.length === 0);
      return;
    }

    const startIndex = fullText.indexOf(startString);
    const endIndex = fullText.indexOf(endString, startIndex + startString.length);

    if (startString && startIndex === -1) {
      setStartStatus('Not found');
    } else if (startString && fullText.indexOf(startString, startIndex + 1) !== -1) {
      setStartStatus('Non-unique');
    } else if (startString) {
      setStartStatus('✔');
    } else {
      setStartStatus('');
    }

    if (endString && endIndex === -1) {
      setEndStatus('Not found');
    } else if (endString && fullText.indexOf(endString, endIndex + 1) !== -1) {
      setEndStatus('Non-unique');
    } else if (endString) {
      setEndStatus('✔');
    } else {
      setEndStatus('');
    }

    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
      const passageText = fullText.substring(startIndex, endIndex + endString.length);
      const wordCountValue = passageText.split(/\s+/).length;
      setPassage(passageText);
      setWordCount(wordCountValue);
      setParseButtonDisabled(false);
    } else {
      setPassage('');
      setWordCount(0);
      setParseButtonDisabled(true);
    }
  }

  function handleParseVocab() {
    if (!password) {
      alert("Please enter a password.");
      return;
    }
    const hashedPassword = sha256(password);
    fetchVocab(inputMode === 'freeText' ? freeText : passage, hashedPassword);
  }

  function fetchVocab(text, hashedPassword) {
    const url = "https://vocab-parser.fly.dev/extract-vocab";
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(':' + hashedPassword)
    });
    const body = JSON.stringify({ text });

    setLoading(true);
    setErrorMessage('');

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    })
      .then(response => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(csv => processCSV(csv))
      .catch(error => {
        setLoading(false);
        setErrorMessage('Error: ' + error.message);
        console.error('Error:', error);
      });
  }

  function processCSV(text) {
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const wordList = results.data
          .filter(row => row['Word/Phrase'] && row['Definition'])
          .map(row => ({
            word: row['Word/Phrase'].trim(),
            definition: row['Definition'].trim()
          }));
        setWords(wordList);
        setTimestamp(getTimestamp());
      }
    });
  }

  function getTimestamp() {
    const now = new Date();
    return now.getFullYear() +
      ('0' + (now.getMonth() + 1)).slice(-2) +
      ('0' + now.getDate()).slice(-2) + '_' +
      ('0' + now.getHours()).slice(-2) +
      ('0' + now.getMinutes()).slice(-2);
  }

  function exportInitialCSV() {
    let csvContent = "data:text/csv;charset=utf-8,";

    words.forEach(word => {
      csvContent += `"${word.word.replace(/"/g, '""')}","${word.definition.replace(/"/g, '""')}"\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `vocabulary_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="App">
      <div id="input-zone">
        <input
          type="password"
          id="password-input"
          placeholder="Enter your password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <div>
          <label>
            <input
              type="radio"
              value="ulysses"
              checked={inputMode === 'ulysses'}
              onChange={() => setInputMode('ulysses')}
            />
            Ulysses
          </label>
          <label>
            <input
              type="radio"
              value="freeText"
              checked={inputMode === 'freeText'}
              onChange={() => setInputMode('freeText')}
            />
            Free Text
          </label>
        </div>
        {inputMode === 'ulysses' ? (
          <>
            <div>
              <input
                type="text"
                id="start-string"
                placeholder="Enter start string"
                value={startString}
                onChange={e => setStartString(e.target.value)}
              />
              <span id="start-status" className={`validation-status ${startStatus === '✔' ? 'valid' : startStatus ? 'invalid' : ''}`}>{startStatus}</span>
            </div>
            <div>
              <input
                type="text"
                id="end-string"
                placeholder="Enter end string"
                value={endString}
                onChange={e => setEndString(e.target.value)}
              />
              <span id="end-status" className={`validation-status ${endStatus === '✔' ? 'valid' : endStatus ? 'invalid' : ''}`}>{endStatus}</span>
            </div>
          </>
        ) : (
          <textarea
            id="free-text-input"
            placeholder="Enter your text here"
            value={freeText}
            onChange={e => setFreeText(e.target.value)}
            rows={10}
            style={{width: '100%', marginBottom: '10px'}}
          />
        )}
        <button className="button" id="parse-vocab" onClick={handleParseVocab} disabled={parseButtonDisabled}>Parse Vocab</button>
        {loading && <div id="loading">Loading...</div>}
        {errorMessage && <div id="error-message">{errorMessage}</div>}
        {wordCount > 0 && <div id="word-count">Word count: {wordCount}</div>}
        {inputMode === 'ulysses' && passage && (
          <div id="selected-passage" style={{maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', marginTop: '10px'}}>
            <h4>Selected Passage:</h4>
            <p>{passage}</p>
          </div>
        )}
      </div>
      {words.length > 0 && (
        <div id="export-container">
          <button id="export-initial-csv" onClick={exportInitialCSV}>Export Vocabulary as CSV</button>
        </div>
      )}
    </div>
  );
}

export default App;